<template>
    <div id="qrcode">
        <div class="d-none d-sm-block">
            <br>
            <br>
            <br>
        </div>

        <p class="error">{{ error }}</p>
        <qrcode-stream @decode="onDecode" @init="onInit" :camera="camera">
            <div class="loading-indicator" v-if="loading">
                Loading...
            </div>
            <div v-show="showScanConfirmation" class="scan-confirmation">
                <img src="../../assets/checkmark.svg" alt="Checkmark" width="128px" />
            </div>
        </qrcode-stream>
    </div>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'

export default {
    name: "ScanQr",
    components: { QrcodeStream },
    data() {
        return {
            error: '',
            loading: false,
            showScanConfirmation: false,
            camera: 'auto'
        }
    },
    methods: {
        onDecode (result) {
            this.pause()
            setTimeout(function (){
                window.location.href = result;
            }, 1800);
        },
        async onInit (promise) {
            this.loading = true

            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permisson"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                }
            } finally {
                this.loading = false;
                this.showScanConfirmation = this.camera === "off"
            }
        },

        unpause () {
            this.camera = 'auto'
        },

        pause () {
            this.camera = 'off'
        },
    }

}
</script>

<style scoped>
.scan-confirmation {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, .8);

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}
</style>
